<template>
  <div style="font-size: 12px; min-width: 1600px;">
    <el-alert v-if="errMsg" :title="errMsg" type="error" center show-icon></el-alert>
    <el-row>
      <el-col :span="4">
        <el-date-picker v-model="date" type="date" @change="changDate"></el-date-picker>
        <table border="0" cellpadding="0" cellspacing="0" style="margin:auto">
          <tr v-for="(item, index) in goalmodelList" :key="index" @click="changeMatch(item)" :class="matchId === item.id?'selectedstyle':''">
            <td>{{index+1}}</td>
            <td>{{item.leagueAbbName}}</td>
            <td>{{item.homeTeamAbbName}}VS{{item.awayTeamAbbName}}</td>
          </tr>
        </table>
      </el-col>
      <el-col :span="8">
        <view>{{homeTeamAbbName}}VS{{awayTeamAbbName}}</view>
        <table border=“1” cellpadding="0" cellspacing="0">
          <tr>
            <td></td>
            <td>场数</td>
            <td>0球</td>
            <td>1球</td>
            <td>2球</td>
            <td>3球</td>
            <td>4球</td>
            <td>5球</td>
            <td>6球</td>
            <td>7球</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <td>初赔10均差</td>
            <td>{{ tuliData.len10 }}</td>
            <td>{{ tuliData.chupei10juncha.s0odds }}</td>
            <td>{{ tuliData.chupei10juncha.s1odds }}</td>
            <td>{{ tuliData.chupei10juncha.s2odds }}</td>
            <td>{{ tuliData.chupei10juncha.s3odds }}</td>
            <td>{{ tuliData.chupei10juncha.s4odds }}</td>
            <td>{{ tuliData.chupei10juncha.s5odds }}</td>
            <td>{{ tuliData.chupei10juncha.s6odds }}</td>
            <td>{{ tuliData.chupei10juncha.s7odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>初赔01均差</td>
            <td>{{ tuliData.len01 }}</td>
            <td>{{ tuliData.chupei01juncha.s0odds }}</td>
            <td>{{ tuliData.chupei01juncha.s1odds }}</td>
            <td>{{ tuliData.chupei01juncha.s2odds }}</td>
            <td>{{ tuliData.chupei01juncha.s3odds }}</td>
            <td>{{ tuliData.chupei01juncha.s4odds }}</td>
            <td>{{ tuliData.chupei01juncha.s5odds }}</td>
            <td>{{ tuliData.chupei01juncha.s6odds }}</td>
            <td>{{ tuliData.chupei01juncha.s7odds }}</td>
          </tr>
        </table>
        <table border=“1” cellpadding="0" cellspacing="0">
          <tr>
            <th></th>
            <th>0球</th>
            <th>1球</th>
            <th>2球</th>
            <th>3球</th>
            <th>4球</th>
            <th>5球</th>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">0球</th>
            <td>{{ tuliData.chupei10juncha.s00s00odds }}</td>
            <td rowspan="2">{{ tuliData.chupei10juncha.s00s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s00s02odds }}</td>
            <td>{{ tuliData.chupei10juncha.s00s03odds }}</td>
            <td>{{ tuliData.chupei10juncha.s00s04odds }}</td>
            <td>{{ tuliData.chupei10juncha.s00s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s00s00odds }}</td>
            <!--td>{{ tuliData.chupei01juncha.s00s01odds }}</td-->
            <td>{{ tuliData.chupei01juncha.s00s02odds }}</td>
            <td>{{ tuliData.chupei01juncha.s00s03odds }}</td>
            <td>{{ tuliData.chupei01juncha.s00s04odds }}</td>
            <td>{{ tuliData.chupei01juncha.s00s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">1球</th>
            <td rowspan="2">{{ tuliData.chupei10juncha.s01s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s02odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s03odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s04odds }}</td>
            <td>{{ tuliData.chupei10juncha.s01s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <!--td>{{ tuliData.chupei01juncha.s01s00odds }}</td-->
            <td>{{ tuliData.chupei01juncha.s01s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s01s02odds }}</td>
            <td>{{ tuliData.chupei01juncha.s01s03odds }}</td>
            <td>{{ tuliData.chupei01juncha.s01s04odds }}</td>
            <td>{{ tuliData.chupei01juncha.s01s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">2球</th>
            <td>{{ tuliData.chupei10juncha.s02s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s02odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s03odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s04odds }}</td>
            <td>{{ tuliData.chupei10juncha.s02s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s02s00odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s02odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s03odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s04odds }}</td>
            <td>{{ tuliData.chupei01juncha.s02s05odds }}</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">3球</th>
            <td>{{ tuliData.chupei10juncha.s03s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s03s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s03s02odds }}</td>
            <td>{{ tuliData.chupei10juncha.s03s03odds }}</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s03s00odds }}</td>
            <td>{{ tuliData.chupei01juncha.s03s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s03s02odds }}</td>
            <td>{{ tuliData.chupei01juncha.s03s03odds }}</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">4球</th>
            <td>{{ tuliData.chupei10juncha.s04s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s04s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s04s02odds }}</td>
            <td>-</td>
            <td>-</td>
            <td>{{ tuliData.chupei10juncha.s_1saodds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s04s00odds }}</td>
            <td>{{ tuliData.chupei01juncha.s04s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s04s02odds }}</td>
            <td>-</td>
            <td>-</td>
            <td>{{ tuliData.chupei01juncha.s_1saodds }}</td>
          </tr>
          <tr v-if="tuliData['chupei10juncha']">
            <th rowspan="2">5球</th>
            <td>{{ tuliData.chupei10juncha.s05s00odds }}</td>
            <td>{{ tuliData.chupei10juncha.s05s01odds }}</td>
            <td>{{ tuliData.chupei10juncha.s05s02odds }}</td>
            <td>-</td>
            <td>{{ tuliData.chupei10juncha.s_1shodds }}</td>
            <td>{{ tuliData.chupei10juncha.s_1sdodds }}</td>
          </tr>
          <tr v-if="tuliData['chupei01juncha']">
            <td>{{ tuliData.chupei01juncha.s05s00odds }}</td>
            <td>{{ tuliData.chupei01juncha.s05s01odds }}</td>
            <td>{{ tuliData.chupei01juncha.s05s02odds }}</td>
            <td>-</td>
            <td>{{ tuliData.chupei10juncha.s_1shodds }}</td>
            <td>{{ tuliData.chupei01juncha.s_1sdodds }}</td>
          </tr>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      matchId: '',
      goalmodelList: [],
      errMsg: '',
      tuliData: {}
    }
  },
  mounted () {
    this.formatDate(new Date())
    this.getGoalmodelList()
  },
  methods: {
    towNumber (val) {
      return val.toFixed(2)
    },
    formatNumber (n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    formatDate (date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      this.date = [year, month, day].map(this.formatNumber).join('-')
    },
    getGoalmodelList () {
      this.$axios.get('api/goalmodel/list', {
        params: {
          date: this.date
        }
      }).then(res => {
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.goalmodelList = temp.data
        }
      })
    },
    changDate (event) {
      this.formatDate(this.date)
      this.getGoalmodelList()
    },
    changeMatch (event) {
      this.homeTeamAbbName = event.homeTeamAbbName
      this.awayTeamAbbName = event.awayTeamAbbName
      this.matchId = event.id
      this.$axios.get('api/admin/zonghetuli/mate', {
        params: {
          matchId: this.matchId
        }
      }).then(res => {
        console.log(res.data)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.tuliData = temp.data
        }
      })
    }
  }
}
</script>

<style>
  td {
    padding: 10px 12px;
  }
  .tableSoccer {
    padding: 3px 5px;
  }
  .selectedstyle {
    background-color: yellowgreen;
  }
</style>
